import React, { useEffect, useState } from 'react';
import config from './config';

const useLogic = () => {
  const [data, setData] = useState({
    clients: [],
    services: [],
    orders: [],
    orderBook: [],
    ta1: [],
  });
  const [debugInfo, setDebugInfo] = useState({});
  const [allMessages, setAllMessages] = useState([]);
  const [directMessages, setDirectMessages] = useState([]);
  const [clientAppMessages, setClientAppMessages] = useState([]);
  const [orderBookMessages, setOrderBookMessages] = useState([]);
  const [technicalAnalysisMessages, setTechnicalAnalysisMessages] = useState([]);
  const [tradeSignalMessages, setTradeSignalMessages] = useState([]);
  const [ordersMessages, setOrdersMessages] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState({ skip: 0, take: pageSize });

  useEffect(() => {
    console.log('Inside useEffect');
    console.log('Configuration:', config);
    console.log('Trying to establish WebSocket connection...');
    const socketUrl = config.ProductionDeployment
      ? 'wss://nodeserver.bitstream.software:6001'
      : 'wss://nodeserverdev.bitstream.software:6001';

    setDebugInfo({ config, socketUrl });

    const socket = new WebSocket(socketUrl);
    socket.addEventListener('open', () => {
      console.log('WebSocket connected');
    });

    socket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      setData((prevData) => ({ ...prevData, ...message }));
      console.log('Received real-time data from ClientHub:', message);

      if (message.action === 'direct-message') {
        setDirectMessages((prevMessages) => [message, ...prevMessages]);
      }
      if (message.action === 'BitStream-message' && message.message.ServiceName === 'ClientApp') {
        setClientAppMessages((prevMessages) => [message, ...prevMessages]);
      }
      if (message.action === 'BitStream-message' && message.message.ServiceName === 'OrderBook') {
        setOrderBookMessages((prevMessages) => [message, ...prevMessages]);
      }
      if (message.action === 'BitStream-message' && message.message.ServiceName === 'TA1') {
        setTechnicalAnalysisMessages((prevMessages) => [message, ...prevMessages]);
      }
      if (message.action === 'BitStream-message' && message.message.TradeSignal === true) {
        setTradeSignalMessages((prevMessages) => [message, ...prevMessages]);
      }
      if (message.action === 'BitStream-message' && message.message.Order === true) {
        setOrdersMessages((prevMessages) => [message, ...prevMessages]);
      }
      if (message.action === 'BitStream-message' && message.message.ServiceName === 'Nexus' && message.message.State === 'OrderSignal') {
        setOrdersMessages((prevMessages) => [message, ...prevMessages]);
      }
      if (message.action === 'BitStream-message' && message.message.ServiceName === 'ClientApp' && message.message.State === 'OnOrderSuccess') {
        setOrdersMessages((prevMessages) => [message, ...prevMessages]);
      }
    });

    socket.addEventListener('error', (error) => {
      console.error('WebSocket error:', error);
    });

    socket.addEventListener('close', () => {
      console.log('WebSocket disconnected');
    });

    return () => {
      socket.close();
      console.log('WebSocket disconnected');
    };
  }, []);

  const handlePageChange = (event) => {
    setPage({
      skip: event.page.skip,
      take: event.page.take,
    });
  };

  const handlePageSizeChange = (event) => {
    const newSize = event.target.value;
    setPageSize(newSize);
    setPage({
      skip: 0,
      take: newSize,
    });
  };

  return {
    data,
    directMessages,
    allMessages,
    clientAppMessages,
    orderBookMessages,
    technicalAnalysisMessages,
    tradeSignalMessages,
    ordersMessages,
    pageSize,
    page,
    handlePageChange,
    handlePageSizeChange,
    debugInfo,
  };
};

export default useLogic;

