export const volumeSeries = [{
   name: "A",
   data: [100, 400, 600, 700, 800, 1000, 1200, 1400, 1600]
 }, {
   name: "B",
   data: [700, 600, 900, 1000, 1400, 1600, 2000, 1600]
 }, {
   name: "C",
   data: [100, 100, 700, 300, 200, 888, 999, 234, 1600]
 }, {
   name: "D",
   data: [500, 800, 800, 1200, 1400, 1600, 2000, 1600]
 }];
