import React, { useState } from 'react';
import { Grid, GridColumn, GridToolbar, GridRow, GridHeaderCell } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { process } from '@progress/kendo-data-query';
import '../styles/bitstream.scss';
import useLogic from './Logic';

const TradeSignalFeed = () => {

  const [sortOrder, setSortOrder] = useState('desc');
  const { data, tradeSignalMessages, directMessages, page, handlePageChange } = useLogic();

  const sortData = (order) => {
    setSortOrder(order);
  };

  const sortedMessages = tradeSignalMessages.slice().sort((a, b) => {
    if (sortOrder === 'asc') {
      return new Date(a.message.CreatedAt) - new Date(b.message.CreatedAt);
    } else {
      return new Date(b.message.CreatedAt) - new Date(a.message.CreatedAt);
    }
  });

  const messagesWithRowNumbers = sortedMessages.map((message, index) => ({
    ...message,
    rowNumber: sortOrder === 'asc' ? index + 1 : sortedMessages.length - index,
  }));
   return (
          <div style={{ marginLeft: '20px' }}>
          <h2>Direct Messages:</h2>
            <ul>
                {directMessages.map((message, index) => (
                    <li key={index}>{JSON.stringify(message)}</li>
                ))}
            </ul>
          <div>
            <h2>Trade Signal Messages:</h2>
            <Grid
              data={process(messagesWithRowNumbers, page)}
              pageable={{ pageSizes: [10, 20, 50, 100] }}
              total={messagesWithRowNumbers.length}
              skip={page.skip}
              take={page.take}
              onPageChange={handlePageChange}
            >
              <GridColumn
                field="rowNumber"
                title="#"
                width="50px"
                render={({ dataItem }) => <td>{dataItem.rowNumber}</td>}
              />
              <GridHeaderCell style={{ background: '#fd7e14', color: 'white' }} />
              <GridColumn
                field="message.CreatedAt"
                title="Created At"
                headerCellProps={{ style: { background: '#fd7e14', color: 'white' } }}
              />
              <GridColumn field="message.ServiceName" title="Service Name" sortable filterable />
              <GridColumn field="message.BitPacketId" title="BitPacketID" />
              <GridColumn field="message.Pair" title="Pair" />
              <GridColumn field="message.LastPrice" title="Price" />
              <GridColumn field="message.PriceDepth" title="Price Depth" />
              <GridColumn field="message.Buy" title="Buy" />
              <GridColumn field="message.Sell" title="Sell" />
              <GridColumn field="message.Share" title="Share" />
              <GridColumn field="message.Qty" title="Qty" />
              <GridColumn field="message.ThresholdValue" title="Threshold" />
              <GridColumn field="message.FinalScore" title="Score" />
              <GridColumn field="message.OrderPercent" title="Order %" />
              <GridColumn field="message.ScoreLevelName" title="Score Level" />
	      <GridColumn field="message.ConnectionId" title="Connection ID" />
              <GridRow style={{ background: '#999999', color: 'white' }} />

              <GridToolbar>
                <button title="Sort ascending" className="k-button" onClick={() => sortData('asc')}>
                  Sort Ascending
                </button>
                <button title="Sort descending" className="k-button" onClick={() => sortData('desc')}>
                  Sort Descending
                </button>
              </GridToolbar>
            </Grid>
          </div>
        </div>
  );
};
export default TradeSignalFeed;
