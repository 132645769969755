import React, { useState } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import ClientFeed from './ClientFeed';
import OrderBookFeed from './OrderBookFeed';
import TechnicalAnalysisFeed from './TechnicalAnalysisFeed';
import TradeSignalFeed from './TradeSignalFeed';
import OrderSignalFeed from './OrderSignalFeed';
import KendoGrid from './KendoGrid';

const Tabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (event) => {
    setSelectedTab(event.selected);
  };

  return (
      <TabStrip onSelect={handleTabSelect} selected={selectedTab}>
        <TabStripTab title="Clients">
          <ClientFeed />
        </TabStripTab>
        <TabStripTab title="OrderBook">
          <OrderBookFeed />
        </TabStripTab>
        <TabStripTab title="TechnicalAnalysis">
          <TechnicalAnalysisFeed />
        </TabStripTab>
	<TabStripTab title="Trade Signals">
          <TradeSignalFeed />
        </TabStripTab>
	<TabStripTab title="Order Signals">
          <OrderSignalFeed />
        </TabStripTab>
      </TabStrip>
  );
};

export default Tabs;

