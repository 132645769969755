import React, { useEffect, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import '@progress/kendo-theme-default/dist/all.css';
import Tabs from './Tabs';
//import { io } from 'socket.io-client'; 
import io from 'socket.io-client';

import Echo from 'laravel-echo';

export const Messages = () => {

	//// MESSAGES 
const [messages, setMessages] = useState([]);

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'socket.io',
      host: window.location.hostname + ':6001', // Adjust the port as needed
    });

    const channel = echo.channel('bitstream');

    channel.listen('BitStreamEvent', (event) => {
      console.log('Received BitStreamEvent:', event);

      // Update state with the received message
      setMessages((prevMessages) => [...prevMessages, event.message]);
    });

    return () => {
      channel.stopListening('BitStreamEvent');
    };
  }, []);
/// end of messages 
  return (
    <div>
      <h1>BitStream Messages</h1>
      <ul>
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </div>
  );
};

