
export const IconHeader = () => {
   return <div className="icon-header-container"> 
     <div>
     <p>Weather</p>
     </div>
      <div>
      <span className="k-icon k-i-globe globe-span"></span>
         <a>Sofia</a>
      </div>
    </div> 
}