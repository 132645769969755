import React from 'react';
import '@progress/kendo-theme-default/dist/all.css';
import Tabs from './Tabs';
import '../styles/bitstream.scss';
import useLogic from './Logic';

export const ServerHub = () => {
    const { data, allMessages, directMessages } = useLogic();

    return ( 
        <div style={{ marginLeft: '20px', marginTop: '150px' }}>
	    {/*<h1>ServerHub Live Feed</h1>
            <div>
                {data && (
                    <div>
                        <h2>Received Data:</h2>
                        <pre>{JSON.stringify(data, null, 2)}</pre>
                    </div>
                )}
                <h2>Received Messages:</h2>
                <ul>
                    {allMessages.map((message, index) => (
                        <li key={index}>{JSON.stringify(message)}</li>
                    ))}
                </ul>
            </div>
            <h2>Direct Messages:</h2>
            <ul>
                {directMessages.map((message, index) => (
                    <li key={index}>{JSON.stringify(message)}</li>
                ))}
            </ul>*/}

            <div style={{ marginLeft: '20px' }}>
                <Tabs data={data} />
            </div>
        </div>
    );
};

